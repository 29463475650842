<template>
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :showButton="false"
        :heading="$t('xpbx.settings.ivrs.heading')"
      />

      <!-- Queues table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="ivrs"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          v-if="!loading"
          :paginator="true"
          :rows="10"
          removableSort
          v-model:selection="selectedRecords"
          v-model:filters="filters"
          paginatorPosition="top"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} flows"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          dataKey="id"
          selectionMode="single"
          :globalFilterFields="['name']"
        >
          <template #empty>
            {{ $t("xpbx.settings.fields.empty_table") }}</template
          >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
              </div>
              <div class="flex gap-2 items-center ml-auto">
                <InputText
                  class="search-input"
                  v-model="filters['global'].value"
                  :placeholder="$t('xpbx.placeholders.search')"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="name"
            style="width: 25%"
            :header="$t('xpbx.settings.ivrs.fields.name')"
          >
            <template #body="{ data }">
              <div class="relative h-full my-2">
                <div class="absolute -top-6">{{ data?.name }}</div>
                <div class="absolute bottom-1 left-0 w-full h-full">
                  <i
                    v-tooltip.top="$t('xpbx.settings.tooltips.is_active')"
                    class="fa-light fa-thumbs-up edit-icon clone-icon"
                    :class="{ inactive: data?.is_active === 0 }"
                    @click="setActive(data)"
                  ></i>
                  <router-link :to="`/xpbx/settings/flow/${data?.id}`">
                    <i
                      class="fa-light fa-pen-to-square edit-icon mx-2 clone-icon"
                      v-tooltip.top="$t('xpbx.settings.tooltips.link')"
                    ></i
                  ></router-link>
                  <i
                    v-tooltip.top="$t('xpbx.settings.tooltips.clone_ivr')"
                    class="fa-light fa-clone edit-icon clone-icon"
                    @click="cloneIVR(data?.id)"
                  ></i>

                  <i
                    @click="deleteSelectedRecord(data)"
                    v-tooltip.top="$t('xpbx.settings.tooltips.delete')"
                    class="fa-light fa-trash edit-icon mx-2"
                  ></i>
                </div>
              </div>
            </template>
          </Column>
          <Column
            sortable
            field="flowtype"
            style="width: 15%"
            :header="$t('xpbx.settings.ivrs.fields.flowtype')"
          >
            <template #body="{ data }">
              <div class="my-2">{{ getFlowType(data?.flowtype) }}</div>
            </template>
          </Column>

          <Column
            sortable
            field="created_at"
            filterField="created_at"
            dataType="date"
            style="width: 8%"
            :header="$t('xpbx.table-field.created_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.created_at" />
            </template>
          </Column>
          <Column
            sortable
            dataType="date"
            field="updated_at"
            filterField="updated_at"
            style="width: 8%"
            :header="$t('xpbx.table-field.updated_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.updated_at" />
            </template>
          </Column>
        </DataTable>
        <Loader v-else />
      </div>

      <!-- DIalogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span>
            {{
              $t("xpbx.settings.ivrs.notification.confirm_delete", {
                delete: `flow ${selectedRecord?.name || ""}`,
              })
            }}</span
          >
        </div>
        <template #footer>
          <Button
            text
            :label="$t('xpbx.button.no')"
            @click="deleteRecordsDialog = false"
          />
          <Button
            text
            :label="$t('xpbx.button.yes')"
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create template -->
      <Dialog
        v-model:visible="ringGroupDialog"
        :style="{ width: '700px' }"
        :header="$t('xpbx.settings.ivrs.dialogs.create_ivr')"
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!--Name -->
        <div class="field">
          <label for="company">{{
            $t("xpbx.settings.ivrs.labels.name")
          }}</label>
          <InputText
            id="name"
            v-model.trim="form.name"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !form.name }"
          />
          <small class="p-error" v-if="!!errors?.name?.[0]">{{
            errors?.name?.[0]
          }}</small>
        </div>
        <QueueHint :title="$t('xpbx.settings.ivrs.hints.name')" />

        <!--Is active -->
        <InputSwitch
          v-model:modelValue="form.is_active"
          :label="$t('xpbx.settings.ivrs.labels.is_active')"
        />

        <template #footer>
          <DialogButtons
            :saveButtonText="$t('xpbx.button.save')"
            :cancelButtonText="$t('xpbx.button.cancel')"
            @save="create"
            @cancel="ringGroupDialog = false"
          />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
import useIvrs from "@/modules/xpbx/composables/useIvrs";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/DateItem/DateItem.vue";
import { validateCreateIVR } from "@/composables/auth/createDomainValidations";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Tooltip from "primevue/tooltip";
import DataTable from "primevue/datatable";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";

export default {
  name: "Flows",

  components: {
    PageHeader,
    DateItem,
    DataTable,
    Column,
    Loader,
    Button,
    InputText,
    Dialog,
    QueueHint,
    InputSwitch,
    DialogButtons,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const defaultOption = ref({ name: "Select option", value: "" });
    // Datatable
    const t = inject("t");
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const form = ref({
      name: "",
      is_active: false,
    });
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const ringGroupDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const {
      ivrs,
      findIvrs,
      cloneIvr,
      createIvr,
      deleteIvr,
      updateIvr,
      loading,
    } = useIvrs();

    // Data table functions
    const openNew = () => {
      form.value = {
        name: "",
        is_active: false,
      };
      isEdit.value = false;
      ringGroupDialog.value = true;
    };

    const closeDialog = (value) => {
      ringGroupDialog.value = value;
    };

    const reset = () => {
      form.value = {
        name: "",
        is_active: false,
      };
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const createRecord = async (record) => {
      const isValid = validateCreateIVR(record);

      if (isValid) {
        const formData = {
          ...record,
          is_active: record.is_active ? 1 : 0,
        };

        await createIvr(formData);

        isEdit.value = false;
        ringGroupDialog.value = false;
      }
    };

    const create = async () => {
      submitted.value = true;
      await createRecord(form.value);
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteIvr(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const cloneIVR = async (id) => {
      await cloneIvr(id);
    };

    const getFlowType = (type) => {
      if (!type) return "";
      return t(`xpbx.settings.ivrs.texts.${type}`);
    };

    const setActive = async (data) => {
      const formData = {
        name: data.name,
        is_active: data.is_active === 1 ? 0 : 1,
      };

      await updateIvr(formData, data.id);
    };

    onMounted(async () => {
      await findIvrs();
    });

    return {
      // Data
      dt,
      ivrs,
      isEdit,
      form,
      loading,
      errors,
      submitted,
      filters,
      ringGroupDialog,
      scrollHeight,
      defaultOption,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      // Methods
      reset,
      create,
      openNew,
      setActive,
      updateIvr,
      cloneIVR,
      createIvr,
      deleteIvr,
      closeDialog,
      getFlowType,
      createRecord,
      clearErrors,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },

  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.regex-example {
  color: #10b981;
  margin-top: 4px;
  display: inline-block;
}

.edit-icon {
  color: #10b981;
}

.clone-icon {
  font-size: 1.1rem;
}

.inactive {
  transform: rotate(180deg);
}
</style>